<template>
  <div class="pd__banners-wrap">
    <div class="pd__banners" v-if="banners && banners.length">
      <div v-for="(item, index) in banners" :key="index" :title="item.title" class="pd__banner _item">
        <div class="img">
          <a :href="item.link" class="img__container" target="_blank">
            <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicDiscussionBanners",
  props: {
    banners: Array,
  },
};
</script>

<style lang="stylus">
.pd {
  &__banners-wrap {
    padding 24px
    background-color var(--color_gray_light)
    height 100%
    +below(1400px) {
      padding 16px
    }
  }

  &__banners {
    display grid
    grid-gap var(--margin)
    overflow auto
    +below(1100px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(680px) {
      display flex
    }
  }

  &__banner {
    +below(680px) {
      flex 0 0 220px
    }

    .img {
      padding-top 100%
      margin-bottom: 0;
    }
  }
}
</style>
