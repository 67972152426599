<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <div class="pd__welcome">
          <BreadcrumbsComponent title="Общественные обсуждения" />
          <div class="pd__welcome-blocks">
            <div class="pd__welcome-block">
              <img src="/public/images/pd/pd_1.svg" alt="" />
              <span>
                Мы оповещаем <br />
                о проекте
              </span>
            </div>
            <div class="pd__welcome-block">
              <img src="/public/images/pd/pd_2.svg" alt="" />
              <span>
                Вы оставляете <br />
                свои комментарии
              </span>
            </div>
            <div class="pd__welcome-block">
              <img src="/public/images/pd/pd_3.svg" alt="" />
              <span>
                Мы реализуем проект <br />
                с учётом вашего мнения
              </span>
            </div>
          </div>
        </div>
        <section class="section">
          <div class="row">
            <div class="col-9">
              <ul class="pd__tabs-list">
                <li class="pd__tabs-item">
                  <a @click.prevent="setPdStatus(null)" :class="{ active: activePdStatus === null }">
                    Все обсуждения
                  </a>
                </li>
                <li v-for="(item, index) in allStatuses" :key="index" class="pd__tabs-item">
                  <a
                    v-if="isPdStatus(item)"
                    @click.prevent="setPdStatus(item)"
                    :class="{ active: activePdStatus === item.id }"
                  >
                    {{ getStatusName(item) }}
                  </a>
                </li>
              </ul>
              <form @submit.prevent="" class="filter__form pd__form" autocomplete="off">
                <div class="search__input__wrap">
                  <input
                    v-model.trim="form.title.value"
                    type="text"
                    placeholder="Введите текст для интерактивного поиска"
                    maxlength="255"
                    @input="requestSearch"
                  />
                  <button v-if="form.title.value" type="button" @click="resetSearchInput">
                    <CloseIcon />
                  </button>
                </div>
              </form>

              <LoadingIndicator v-if="loading" title="Загрузка" />
              <div
                v-else-if="publicDiscussions && publicDiscussions.data && publicDiscussions.data.length"
                class="pd__list"
              >
                <router-link
                  v-for="(item, index) in publicDiscussions.data"
                  :key="index"
                  :to="{ name: 'pd_open', params: { id: item.id } }"
                  class="pd__item"
                  :class="getClass(item)"
                >
                  <div class="pd__item-img">
                    <div class="img__container">
                      <img :src="item.head_img | image($store.state.api)" alt="" />
                    </div>
                    <div v-if="item.title" class="pd__item-description">
                      {{ item.title }}
                    </div>
                  </div>
                  <div v-if="item.address" class="pd__item-title">{{ item.address }}</div>
                  <div class="pd__item-bottom">
                    <div v-if="item.status && item.status.name" class="pd__item-status">
                      <template v-if="isFinish(item)">
                        Завершено: {{ getDate(item) | humanDateRu }}
                      </template>
                      <template v-else>
                        {{ item.status.name }}
                      </template>
                    </div>
                    <time v-if="!isFinish(item)" :time="getDate(item) | robotDate" class="pd__item-time"
                      >{{ getDate(item) | formatDate }}
                    </time>
                  </div>
                </router-link>
              </div>
              <p v-else-if="form.title.value" class="_item__text">
                По запросу "{{ form.title.value }}" ничего не найдено
              </p>
              <p v-else class="_item__text">Обсуждения отсутствуют</p>
            </div>
            <div v-if="banners && banners.length" class="col-3">
              <Banners :banners="banners" />
            </div>
          </div>
          <PaginationComponent
            v-if="
              page !== null &&
              publicDiscussions &&
              publicDiscussions.paginatorInfo &&
              publicDiscussions.paginatorInfo.total !== null &&
              publicDiscussions.paginatorInfo.total > first
            "
            :total="Math.ceil(publicDiscussions.paginatorInfo.total / first)"
            @change="paginate"
            :page="page"
          />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PaginationComponent from "@/components/Pagination.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import Banners from "./components/Banners.vue";
import PAGINATE_DISCUSSIONS from "@/graphql/pd/Discussions.graphql";

export default {
  name: "PublicDiscussions",
  components: {
    BreadcrumbsComponent,
    LoadingIndicator,
    PaginationComponent,
    CloseIcon,
    Banners,
  },
  data() {
    return {
      page: 1,
      first: 9,
      activePdStatus: null,
      loading: false,
      form: {
        title: {
          value: null,
          message: null,
        },
      },
    };
  },
  created() {
    this.load();
  },
  computed: {
    publicDiscussions() {
      return this.$store.state.publicDiscussionsPage?.PaginateActiveDiscussions;
    },
    allStatuses() {
      const status = this.$store.state.status;
      if (
        this.$store.state.publicDiscussionsPage &&
        this.$store.state.publicDiscussionsPage.AllStatuses &&
        this.$store.state.publicDiscussionsPage.AllStatuses.length
      ) {
        return this.$store.state.publicDiscussionsPage.AllStatuses.filter((item) => {
          return [status.CODE_PD_NOTIFICATION, status.CODE_PD_DISCUSSION, status.CODE_PD_FINISH].includes(
            item.code
          );
        });
      }
      return [];
    },
    banners() {
      return this.$store.state.publicDiscussionsPage?.PaginateBanners?.data || [];
    },
  },
  methods: {
    requestSearch() {
      clearTimeout(this.timeout);
      this.loading = true;
      this.timeout = setTimeout(() => {
        this.load();
      }, 500);
    },
    async load() {
      this.loading = true;
      await this.$apollo.provider.clients.pd
        .query({
          query: PAGINATE_DISCUSSIONS,
          variables: Object.assign({
            statuses: this.activePdStatus ? [this.activePdStatus] : null,
            title: this.form.title.value,
            page: this.page,
            first: this.first,
          }),
        })
        .then(({ data }) => {
          this.loading = false;
          this.$store.commit("publicDiscussionsPage", data);
        });
    },
    paginate(e) {
      this.page = e;
      this.load();
    },
    setPdStatus(item) {
      if (item) {
        this.activePdStatus = item.id;
      } else {
        this.activePdStatus = null;
      }
      this.page = 1;
      this.load();
    },
    getClass(item) {
      let code = item && item.status && item.status.code ? item.status.code : null;
      switch (code) {
        case this.$store.state.status.CODE_PD_NOTIFICATION:
          return "pd__item-notification";
        case this.$store.state.status.CODE_PD_DISCUSSION:
          return "pd__item-exposition";
        case this.$store.state.status.CODE_PD_FINISH:
          return "pd__item-completed";
      }
    },
    getDate(item) {
      let code = item && item.status && item.status.code ? item.status.code : null;
      switch (code) {
        case this.$store.state.status.CODE_PD_NOTIFICATION:
          return item.notification_start_date;
        case this.$store.state.status.CODE_PD_DISCUSSION:
          return item.exhibition_start_date;
        case this.$store.state.status.CODE_PD_FINISH:
          return item.finish_date;
      }
    },
    getStatusName(status) {
      let code = status && status.code ? status.code : null;
      switch (code) {
        case this.$store.state.status.CODE_PD_NOTIFICATION:
          return "Оповещения";
        case this.$store.state.status.CODE_PD_DISCUSSION:
          return "Экспозиции";
        case this.$store.state.status.CODE_PD_FINISH:
          return "Заключения";
      }
    },
    isFinish(item) {
      return item && item.status && item.status.code
        ? item.status.code === this.$store.state.status.CODE_PD_FINISH
        : false;
    },
    isPdStatus(item) {
      return item && item.code
        ? [
            this.$store.state.status.CODE_PD_NOTIFICATION,
            this.$store.state.status.CODE_PD_DISCUSSION,
            this.$store.state.status.CODE_PD_FINISH,
          ].includes(item.code)
        : false;
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
      this.load();
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/mixins/textcut.styl"

.pd__welcome {
  position relative
  background-image url("/public/images/sc/sc_welcome.png"), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%, #DDE8EF 100%)
  background-repeat no-repeat
  background-size auto
  background-position: center;
  border-bottom 1px solid var(--color_gray_divider)

  &-blocks {
    display flex
    align-items center
    justify-content space-between
    padding 105px 64px 132px
    gap 32px
    overflow-x auto
    +below(880px) {
      padding-left 24px
      padding-right 24px
      gap 64px
    }
    +below(768px) {
      padding-top 60px
      padding-bottom 60px
    }

    &::-webkit-scrollbar {
      display none
    }

    img {
      width 128px
      min-width 128px
      height 128px
      object-fit contain
      +below(1500px) {
        width: 80px
        min-width 80px
        height: 80px
      }
    }
  }

  &-block {
    flex-shrink 0
    display flex
    align-items flex-end
    gap 16px
    +below(1200px) {
      flex-direction column
      align-items flex-start
    }

    span {
      flex-shrink 0
      font-weight: 600;
      font-size: 1.5em
      line-height: 40px;
      text-transform: uppercase;
      +below(1500px) {
        font-size: 1.25em
        line-height: 32px
      }
    }
  }
}

.pd {
  &__tabs-list {
    display flex
    flex-direction row
    margin-bottom 32px
    gap 32px
    overflow-x auto
    +below(768px) {
      margin-bottom 24px
      gap 24px
    }

    &::-webkit-scrollbar {
      display none
    }
  }

  &__tabs-item {
    flex-shrink 0
    font-size: 1.125em;
    line-height: 28px
    +below(768px) {
      font-size 1em
      line-height 20px
    }

    > a {
      white-space nowrap
      color var(--color_gray_dark)
      padding 8px 0

      &:hover {
        color var(--color_black)
      }

      &.active {
        color var(--color_black)

        &:after {
          content ''
          display block
          margin-top 8px
          width 100%
          height 3px
          background-color var(--color_blue)
          animation-name line
          animation-duration 0.3s
          animation-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1.15);
        }
      }
    }
  }

  &__form {
    margin-bottom: 32px;
    +below(1200px) {
      margin-bottom: 24px;
    }
    +below(768px) {
      margin-bottom: 16px
    }
  }

  &__list {
    display grid
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    +below(1200px) {
      grid-gap 24px
    }
    +below(880px) {
      grid-template-columns repeat(2, 1fr)
      grid-gap 16px 24px
    }
    +below(620px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    display flex
    flex-direction column
    padding 12px 16px
    border 1px solid var(--color_gray_divider)

    &:hover {
      color inherit
      border-color: var(--color_gray_divider)

      .pd__item-description {
        visibility visible
        opacity 1
        transform translateY(0)
      }
    }

    &-img {
      ratio(328, 313)
      margin-bottom: 12px;
      overflow hidden
    }

    &-description {
      position absolute
      left 0
      right 0
      top 0
      bottom 0
      visibility hidden
      opacity 0
      transition all 0.2s
      transform translateY(50%)
    }

    &-title {
      font-weight: 500;
      margin-bottom: 12px;
      textcut(3)
    }

    &-bottom {
      display flex
      justify-content space-between
      font-size: 0.875em
      line-height: 140%
      margin-top: auto;
    }

    &-status {
      margin-right: 10px;
    }

    &-time {
      color var(--color_gray_dark)
    }
  }

  &__item-notification {
    background-color #E6F1FC

    .pd__item-status {
      color #0372D9
    }

    .pd__item-description {
      background linear-gradient(0deg, rgba(230, 241, 252, 0.9), rgba(230, 241, 252, 0.9))
    }
  }

  &__item-exposition {
    background-color #E6F6F2

    .pd__item-status {
      color #2F8143
    }

    .pd__item-description {
      background linear-gradient(0deg, rgba(230, 246, 242, 0.9), rgba(230, 246, 242, 0.9))
    }
  }

  &__item-completed {
    .pd__item-status {
      color var(--color_gray_dark)
    }

    .pd__item-description {
      background linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))
    }
  }
}
</style>
